import React from 'react'
import './Footer.css'
import Right from './Right.js'
import Icon from './components/icons'
import {Link} from 'react-router-dom'
import whitepaper from './whitepaper.pdf'


function Footer(){

return(

	

  <footer className="footer">
  	 <div className="container">
  	 	<div className="row">
  	 		<div className="footer-col">
  	 			<h4>Information</h4>
  	 			<ul>
  	 				<li><a href="https://bscscan.com/token/0xa5283B31fB971dFcBc1dD9243B16152C782fCFBD">BscScan</a></li>
  	 			{/*	<li><a href="/">CoinMarketCap</a></li>
  	 				<li><a href="/">CoinGecko</a></li> */}
  	 				<li><a href="https://poocoin.app/tokens/0xa5283b31fb971dfcbc1dd9243b16152c782fcfbd">PooCoin</a></li>
  	 			</ul>

				   <h4  style = {{marginTop:"30px"}}>Write Us</h4>
  	 			<ul>
				   <li ><a style={{textTransform:"lowercase"}} href="mailto:info@kookertoken.com?">info@kookertoken.com</a></li>
  	 			
  	 			
					  
  	 			</ul>
				  
  	 		</div>


  	 		<div className="footer-col">
  	 			<h4>Company</h4>
  	 			<ul>
  	 				<li><Link to="/disclaimer">Disclaimer</Link></li>
  	 				<li><Link to="RiskDisclosure">Risk & Disclosure</Link></li>
  	 				<li><Link to="/privacy">Privacy Policy & Cookies</Link></li>
					 <li>  <a href= {whitepaper} download = "Whitepaper.pdf"> Whitepaper</a></li>
				
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>Important Quides</h4>
  	 			<ul>
  	 				<li><Link to="/support">Support</Link></li>
					   <li><Link to="/howtobuy">How To Buy KooKer</Link></li>
					<li>   <a href="https://honeypot.kookertoken.com"> Check Honeypot Tokens</a> </li>
  	 			</ul>
  	 		</div>
  	 		<div className="footer-col">
  	 			<h4>Social</h4>
  	 			<div className="social-links">
  	 				<a href=" https://twitter.com/KooKer_KK"><Icon className="fab fa-twitter" />Twitter</a>
  	 				<a href="https://t.me/KooKer_Official"><Icon className="fab fa-telegram"/>Telegram</a>
  	 				<a href="https://www.instagram.com/kooker_kk"><Icon className="fab fa-instagram" />Instagram</a>
  	 				
  	 			</div>
  	 		</div>
  	 	</div>
  	 </div>
	    <Right/>
  </footer>
  

)
}
export default Footer;
