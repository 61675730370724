import React from 'react';
import './Banner.css'
import kookerVideo from './kookerVideo.mp4'


function Banner() {
  return (
    <div className = 'welcome' > 
   
  
 <div className ='video-container'>
    <video autoPlay loop muted >
<source src ={kookerVideo}  type = " video/mp4"/>
    </video>
   
   <div className ='content' >
    
    <h1>   Welcome To KOOKER</h1>
   
   <p>   The Most Trusted And Growing Crypto Community In The World</p>
    </div>
    </div>
    </div>


   

  )
}

export default Banner;
