import React from 'react';
import Footer from './Footer.js'
//import { FooterContainer } from './containers/footer'
import Home from './Home.js' 
import Navbar from './components/Navbar.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './About.js';
// import Nft from './Nft.js';
//import Team from './Team.js';
import Connect from './Connect.js';
import Privacy from './Privacy.js'
import Terms from './Terms.js'
import Disclaimer from './Disclaimer.js'
import RoadMap from './RoadMap.js'
import Support from './Support.js'
import CookieConsent from "react-cookie-consent";
import Live from './Live.js'
import ScrollToTop from './ScrollToTop'
import HowToBuy from './HowToBuy.js';




function App() {
  return (
   <div className='app'>

<Router > 
<ScrollToTop/>  
      <Navbar /> 
   
      <Routes>

        
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
     {/*   <Route path='/nft' element={<Nft/>} />   */}
        <Route path='/roadmap' element={<RoadMap/>} />
    {/*    <Route path='/team' element={<Team/>} />  */}
        <Route path='/buy' element={<Connect/>} />
        <Route path='/dapp' element={<Live/>} />

<Route path="/privacy" element={<Privacy />} />
<Route path="/RiskDisclosure" element={<Terms />} />
<Route path="/disclaimer" element={<Disclaimer />} />
<Route path="/support" element={<Support />} />
<Route path="/howtobuy" element={<HowToBuy />} />
        
   


</Routes>
<Footer/>

{/*<FooterContainer />*/}
 
</Router>


  

   <CookieConsent debug ={true}
    location="bottom"
    buttonText=" I Agree !!"
    cookieName="KooKerCookies"
    style={{ background: "black", fontSize: "28px" ,
    fontFamily:  "Lucida Sans" }}

    buttonStyle={{ fontWeight: "700", background: "#ff9c00", color: "#ffff", fontSize: "22px" }}
   
    expires={4000000000}
  
   >

   This website uses cookies to enhance the user experience. See our <a style = {{ color: "#ff9c00"}}href="/privacy"> privacy policy </a> for more{" "}
  
   </CookieConsent>
   </div>
  );
} 

export default App;
