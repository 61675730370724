import React from 'react';
import './RoadMap.css'

function RoadMap() {
  return (

    <div className="roadmap" id='roadmap'>
      <h1>RoadMap</h1>
      <p style={{color:"whitesmoke",textAlign:"center", fontSize:"18px"}}>There is no sequencial order to achieve the objectives in the roadmap</p>
      <div className="containers">
        <div className="timeline">
          <ul>
            <li>
              <div className="timeline-content">
               {/* <h3 className="date">Q1/2022</h3> */}
                <h1>Phase 1</h1>
                <p>
                  <input type="checkbox" checked style={{ pointerEvents: "none" }} /> Project Planning <br />
                  <input type="checkbox" checked style={{ pointerEvents: "none" }} /> Smart Contract Deployment<br />
                  <input type="checkbox" checked style={{ pointerEvents: "none" }} /> Creation of Instagram, Twitter & Telegram Account<br />
                  <input type="checkbox" checked style={{ pointerEvents: "none" }} /> PancakeSwap Listing<br />
                  <input type="checkbox" checked style={{ pointerEvents: "none" }} /> Release Of New Website<br />
                  <input type="checkbox" checked style={{ pointerEvents: "none" }} /> Marketing Team Initiates Campaigns<br />
                  <input type="checkbox" checked style={{ pointerEvents: "none" }} /> 25% Of Tokens Airdropped To Early Supporters<br />
                </p>
              </div>
            </li>


            <li>
              <div className="timeline-content">
              {/*  <h3 className="date">Q2,Q3/2022</h3> */}
                <h1>Phase 2</h1>
                <p>
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Listing On CoinMarketCap<br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Listing On CoinGecko<br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Influencer Agreement<br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} /> Further Ecosystem Development <br />
                

                </p>
              </div>
            </li>



            <li>
              <div className="timeline-content">
               {/* <h3 className="date">Q4/2022</h3> */}
                <h1>Phase 3</h1>
                <p>
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Partnerships<br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  More Marketing Campaigns <br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  More influencers<br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  KooKer Live Streaming Services <br />
                  

                </p>
              </div>
            </li>



            <li>
              <div className="timeline-content">
               {/* <h3 className="date">Q1/2023</h3> */}
                <h1>Phase 4</h1>
                <p>
              
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Provide Liquidity On More DEX Platform<br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  More Strategic Marketing <br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  NFTs<br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Staking and Rewards <br />

                </p>
              </div>
            </li>



            <li>
              <div className="timeline-content">
               {/* <h3 className="date">Q2/2023</h3> */}
                <h1>Phase 5</h1>
                <p>
                 
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  More Marketing Campaigns <br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  More Partnerships Signing <br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Major Exchange listing <br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  KooKerSwap <br />
                  <input type="checkbox" style={{ pointerEvents: "none" }} />  Ethereum Smart Contract<br />

                </p>
              </div>
            </li>



            <li>
              <div className="timeline-content">
            {/*    <h3 className="date">Q4/2023</h3> */}
                <h1>Phase 6</h1>
                <p>

                  <input type="checkbox" style={{ pointerEvents: "none" }} />  More To Come.....The Future Is Pregnant <br />

                </p>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>




  );
}

export default RoadMap;
