import React, { useState } from 'react';
// import { Button } from './Button';
//import { Link } from 'react-router-dom';
//import { Link } from 'react-scroll'
import  {HashLink as Link} from 'react-router-hash-link'
import './Navbar.css';
import logo from './logo.png'
//import {animateScroll as scroll } from 'react-scroll'


function Navbar() {
  const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  /*
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
*/


  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/#' className='navbar-logo' onClick={closeMobileMenu}
          
          activeClass='active'
               smooth ={true}
                duration = {500}
                 syp = {true} 
                 exact='true' 
                 offset={-80}
                 activeClassName='selected' 

          >
             <img src={logo} alt ='icon' className = 'mylogo' />
        {/*   <i class='fab fa-typo3' /> */} {/*<h2>KooKer</h2> */}
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/#' className='nav-links' onClick={closeMobileMenu}
               activeClass='active'
               smooth ={true}
                duration = {500}
                 syp = {true} 
                 exact='true' 
                 offset={-80}
                 activeClassName='selected' 
              >
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link 
                to='/#about'
                className='nav-links'
                onClick={closeMobileMenu}
                activeClass='active'
                smooth ={true}
                 duration = {500}
                  syp = {true} 
                  exact='true' 
                  offset={-80}
                  activeClassName='selected' 
              >
                About Us
              </Link>
           </li>
{/*

            <li className='nav-item'>
              <Link
                to='/#nft'
                className='nav-links'
                onClick={closeMobileMenu}

                activeClass='active'
                smooth ={true}
                 duration = {500}
                  syp = {true} 
                  exact='true' 
                  offset={-80}
                  activeClassName='selected' 
              >
                NFT
              </Link>
            </li>
*/}

<li className='nav-item'>
              <Link to='/#roadmap'
                className='nav-links'
                onClick={closeMobileMenu}
                activeClass='active'
             smooth ={true}
              duration = {500}
               syp = {true} 
               exact='true' 
               offset={-80}
               activeClassName='selected' >
               RoadMap
              </Link>
            </li>       
{/*

<li className='nav-item'>
              <Link to='/#team'
                className='nav-links'
                onClick={closeMobileMenu}
                activeClass='active'
             smooth ={true}
              duration = {500}
               syp = {true} 
               exact='true' 
               offset={-80}
               activeClassName='selected' >
               Our Team
              </Link>
            </li>

*/}

            <li className='nav-item'>
              <Link to='/#dapp'
                className='nav-links'
                onClick={closeMobileMenu}
                activeClass='active'
             smooth ={true}
              duration = {500}
               syp = {true} 
               exact='true' 
               offset={-80}
               activeClassName='selected' >
                Dapp
              </Link>
            </li>

            <li>
              <a 
                href= "https://pancakeswap.finance/swap" //to='/#buy'


                className='nav-links-mobile'
                onClick={closeMobileMenu}

                activeClass='active'
                smooth ={true}
                 duration = {500}
                  syp = {true} 
                  exact='true' 
                  offset={-80}
                  activeClassName='selected' 
              >
                Buy KooKer
              </a>
            </li>

          </ul>


      {/*   {button && <Button buttonStyle='btn--outline'> BUY KOOKER</Button>} */}
      <li className='nav-item'>
              <a style={{color:"#ff9c00", fontWeight:"800", marginLeft:"20px"}} href='https://pancakeswap.finance/swap'  className='nav-links' onClick={closeMobileMenu}
                
              >
              BUY KOOKER
              </a>
            </li>
        
        </div>
      </nav>
    </>
  );
}

export default Navbar;