
import React from 'react';
import './Eco.css';
import CardItem from './CardItem';
import lock from './images/lock.png';
import marketing from './images/marketing.png';
import money from './images/money.svg';
import defi from './images/defi.png';
import fire from './images/fire.png';


    function Eco() {
      return (
        <div className='cards'>
          <h1>KOOKER ECOSYSTEM</h1>
          <div className='cards__container'>
            <div className='cards__wrapper'>
              <ul className='cards__items'>
                <CardItem
                h1='Decentralized'
              src= {defi}
                  text='KooKer Token is fully decentralized with no one main owner.
                  A self-feeding LP, and with in-bulid reflections directly to its holders.
                  All decisions are made to favor the community.
        '
                  label=''
                  path='/'
                />
                 <CardItem
                h1='Safe & Secure'
              src={lock}
                  text='5% LP tax goes directly to the liquidity pool to strengthen the token price
                  by reducing the number of circulating tokens, in turn increasing the value of your holdings.
                  All Liquidity is locked.'
                  label=''
                  path='/'
                />
              </ul>
              <ul className='cards__items'>
              <CardItem
                h1='Passive Income'
              src={money}
                  text=' KooKer token rewards its holders with a 3% tax on each transaction. consider it a gift.'
                  label=''
                  path='/'
                />
               <CardItem
                h1='Marketing & Charity'
              src={marketing}
                  text=' 5% of each and every transaction goes to marketing,
                  social media influencers, and charities of our Community of choice.'
                  label=''
                  path='/'
                />

<CardItem
                h1='Manual Burn'
              src={fire}
                  text='Our tokens are manually burned.'
                  label=''
                  path='/'
                />
               
              </ul>
            </div>
          </div>
        </div>
      );
    }
    
    




export default Eco;
