import React from 'react'
import './Home.css'
import Banner from './Banner.js'
import Address from './Address.js'
import RoadMap from './RoadMap.js'
//import Team from './Team.js'
import About from './About.js'
import Donation from './Donation.js'
// import Nft from './Nft.js'
import Terms from './Terms.js' 
import Live from './Live.js'

//import KookerPrice from './KookerPrice'
import CoinInfo from './CoinInfo'





function Home() {
    return (
    <div className='home' >
  
      <Banner/>
      <CoinInfo/>
      <Address/>
      <About/>
       
      <RoadMap/>
     
     {/* <Team/>  */}
       <Live/>
      
      <Donation/>
       <Terms/>

            
    </div>
    )
}

export default Home
