import React from 'react';
import Icon from './components/icons'
import './Support.css'


function Support() {
  return(

      <div className = 'support'  style={{

        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        maxWidth: "1120px",
        width:" 90%",
        margin:" 0 auto"
              }}>
  
  <h1> Sorry There Is No Customer Support </h1>
         
    
  
      <div className="moreSupport" >
         
          <p style={{color:"whitesmoke", fontSize:"18px"}}>
           
           Admins will NEVER send you a direct message. 
           If anybody approaches you directly on e.g. Instagram pretending to 
            represent customer support, please block them and report as spam. 
           NEVER, under any situation, should you ever give someone your private
            key or recovery phrases. Immediately block and report anyone that asks for them.
       </p>
      
 
      </div>
     
      <div className= 'contact'>
          <h1>Join Our Community </h1>
          <div className ='social'>
          <a href="https://twitter.com/KooKer_KK"><Icon className="fab fa-twitter" /></a>
        <a href="https://www.instagram.com/kooker_kk"><Icon className="fab fa-instagram" /></a>
        <a href="https://t.me/KooKer_Official"><Icon className="fab fa-telegram" /></a>
            </div>
        
            </div>
            
</div>
            
      
  );
}

export default Support;
