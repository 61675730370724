import React from 'react';
import './About.css'
import Eco from './Eco.js'
import HowToBuy from './HowToBuy';
//import Download from './Download.js'
import whitepaper from './whitepaper.pdf'
import Icon from './components/icons'




function About() {
    return (
       <div className='about' id = 'about'>

<div className='link__container'>
            <div className='link__wrapper'>
              <ul className='link__items'>
    <li>

           <div className = "links" >  
             <a href = 'https://pancakeswap.finance/swap'>Buy On PancakeSwap</a>
            </div>
           
  </li>


  <li>

<div className = "links" >  
  <h1 style={{color:"#ffffff"}}> ** Set Slipage to 15% **</h1>
 </div>

</li>

  <li>
            <div className = 'links'  >
            <a href="https://t.me/KooKer_Official"> <Icon className="fab fa-telegram" /> Join Telegram</a>

            </div>
            </li>

            <li>

            <div className = 'links'  > 
             <a href= {whitepaper} download = "Whitepaper.pdf"> Whitepaper</a>
                </div>
                </li>

                </ul>

</div>
</div>
 
 
              
                <div className="rightDetails">
                   
                    <h4>KooKer (KK)</h4>
                    <h3>Why Invest In KooKer(KK) ? </h3>
                    <p> KooKer (KK) is a community-driven and deflationary token on the Binance Smart Chain, aiming to revolutionize Defi Ecosystem. The loopholes in other projects have inspired Kooker to become a game-changer when it comes to transparency, safety, and security which will give our investors peace of mind when investing in the KooKer token.
 KooKer lives streaming Service will help new investors and those already trading to check for rug pull tokens, honeypot tokens, and check if a contract is verified on the Binance Smart Chain. Also, monitors for newly created tokens pairs. Everyone is entitled to an opportunity, and KooKer token is here to do just that.  </p>
                    
                </div>
      <HowToBuy/>    
  
          
    <Eco />   
       </div>
    
        
      );
}

export default About;
