import React , {useState, useEffect} from 'react'
import pic from './pic.png'
import './CoinInfo.css';
//import KookerPrice from './KookerPrice.js'

function CoinInfo() {




  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch("https://api.pancakeswap.info/api/v2/tokens/0xa5283B31fB971dFcBc1dD9243B16152C782fCFBD")  
 
      .then((res) => res.json())
      .then((data) => {        
       setPrice(parseFloat(data.data.price).toFixed(16)); 
      //setPrice(data.result.tokenName); 
        setLoading(false);       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className = "coininfo">
    <div className='coin__container'>
            <div className='coin__wrapper'>
              <ul className='coin__items'>
    
      <li>
          <div className="coin_content">
         
            <h1>Total Supply</h1>
     <p>
     69,000,000,000,000,000,000,000<br/>
</p>
</div>
  </li>
 
  
<li>
          <div className="coin_content">
         
          <h1>Locked LP Info</h1>
          <a href="https://dxsale.app/app/v3_3/dxlockview?id=0&add=0xe2D813a629303c9cC753492A0956c607E4276f28&type=lplock&chain=BSC"> Click To Check</a>   
          </div>
        </li>
      
        
<li>
          <div className="coin_content">
          <h1>Kooker Price</h1>


          <div className="kk-logo" style={{   textAlign:"center",
     justifyContent:"center", alignItems:"center"  ,alignContent:"center",  display: "flex", 
     marginTop:"10px"}}> 
       <img  src={pic} alt="KooKer" style={{height:"30px",width:"30px", marginRight: "5px", borderRadius:"50%"}}/>  
      


          <div className="kk-price" style={{color:"#808080",
        alignItems:"center" ,  textAlign:"center", fontSize:"20px" , fontWeight:"bold"}} >
        
          {loading ? "LOADING" : "$" + price}
        </div>
          </div>
          </div>
        </li>
      
 
      </ul>

      </div>
      </div>
      </div>
   
  
  


  );
}


export default CoinInfo