import React from 'react'
import './HowToBuy.css'

function HowToBuy() {
  return (
    <div className="how-to-buy">
                    <h2>How to buy KooKer</h2>
                    <div className ="how">
                        <p>

<h5>Create wallet </h5>
Create a MetaMask or Trust Wallet using either a desktop computer or mobile device. 


<h5>Buy BNB </h5>
Buy BNB directly on MetaMask or Trust wallet or transfer it to your Wallet from exchanges like Crypto.com, Binance, etc.



<h5>Connect your wallet to PancakeSwap </h5>
 Click **Connect wallet** and select your MetaMask or Trust, then confirm it.


<h5>Swap BNB for KooKer</h5>
You can  **Select a token** and enter KooKer token address. Then Confirm import. 
**Enter the amount of BNB and then set slipage to 15%. Click **SWAP** and Confirm 

                        </p>
                    </div>
                </div>

   
  )
}

export default HowToBuy