import React from 'react';


function Terms() {
    return (

<div className = 'terms'  style={{paddingTop:"40px" , marginBottom:"20px"}}>
  
<h1>RISK & DISCLOSURE</h1>
       
  
    <div className="moreTerms"  style={{

display: "flex",
flexFlow: "column",
alignItems: "center",
maxWidth: "1120px",
width:" 90%",
margin:" 0 auto"
      }}> 
     
       
        <p style={{color:"whitesmoke", fontSize:"18px"}}>
          Please note that there are always risks associated with smart contracts. Please use them at your own risk. KooKer is not a registered broker, analyst, or investment advisor. Everything that is provided in this material is purely for guidance,informational and educational purposes. All information contained herein should be independently verified and confirmed. KooKer does not accept any liability for any loss or damage whatsoever caused in reliance upon such information or services. Please be aware of the risks involved with any trading done in any financial market. Do not trade with money that you cannot afford to lose. When in doubt, you should consult a qualified financial advisor before making any investment decisions.
     </p>
    
    </div>

          
</div>
          
    
);
}



export default Terms;

