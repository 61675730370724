import React from 'react'
import './Disclaimer.css'


function Disclaimer() {
    return (
       
  <div className = 'disclaimer'   style={{paddingTop:"40px" , marginBottom:"20px"}}>
  
  

  <h1>disclaimer</h1>
      
    
      <div className="moreDisclaimer" style={{

display: "flex",
flexFlow: "column",
alignItems: "center",
maxWidth: "1120px",
width:" 90%",
margin:" 0 auto"
      }}>
     
         
          <p style={{color:"whitesmoke", fontSize:"18px"}}>
           
          The information provided in this material does not constitute investment advice,
          financial advice, trading advice, or any other sort of advice.
           You should not treat any of the material’s content as such. 
           This material is for informational purposes only and is not (i) an offer,
            or solicitation of an offer, to invest in, or to buy or sell, any interests 
            or shares, or to participate in any investment or trading strategy, (ii) intended 
            to provide accounting, legal, or tax advice, or investment recommendations, or
             (iii) an official statement of KooKer. No representation or warranty is made,
              expressed or implied, with respect to the accuracy or completeness of the 
              information or to the future performance of any digital asset, financial 
              instrument, or other market or economic measure. The KooKer team does not 
              recommend that any cryptocurrency should be bought, sold, or held by you. 
              Do conduct your due diligence and consult your financial advisor before making
               any investment decisions. By purchasing KooKer, you agree that you are not 
               purchasing a security or investment, and you agree to hold the team harmless 
               and not liable for any losses or taxes you may incur. You also agree that the 
               team is presenting the token "as is" and is not required to provide any support
                or services. You should have no expectation of any form from KooKer and its
                 team. Although KooKer is a community-driven decentralized finance (DeFi) token
                  for social networking and not a registered digital currency, the team strongly
                   recommends that citizens in areas with government bans on cryptocurrencies do
                    not purchase it because the team cannot ensure compliance with established 
                    regulations within certain territories. Always make sure that you comply with
                     your local laws and regulations before you make any purchase.
       </p>
      
      
      </div>
      
</div>
            
      
  );
}


export default Disclaimer
