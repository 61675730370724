import React from 'react'
import './Live.css'

function Live() {
  return (
    <div className = "live" id = "dapp">
  
  <h1>KooKer Tools</h1>
 
  <p style={{ color:"#ffffff",
     fontSize:"18px", marginBottom:"10px", textAlign:"center"}}>Make use of our tools which will guide you when 
     dealing with smart contracts. Also, do your own research
     and use our services at your own risk.</p>
<div className = "title">

<h4>Token Live Stream</h4>
<h5>Coming Soon</h5>
</div>
  
    <div className="detail">
     
       
        <p style={{ color:"#ffffff",
     fontSize:"18px", marginBottom:"10px", textAlign:"center"}}>
        KooKer live streaming service will help you check for rug pull tokens and honeypot tokens. Newly created tokens pairs on Pancakeswap will be listed here. 
     </p>
      </div>
      <div className="title"  style={{

display: "flex",
flexFlow: "column",
alignItems: "center",
maxWidth: "1120px",
width:" 100%",
margin:" 0 auto"
      }}>
  <h4 style={{color:"#ff9c00", textAlign:"center", width:"100%"}}>Honeypot Detector</h4>
  <p style={{ 
     fontSize:"18px", marginBottom:"10px", textAlign:"center"}}>Use KooKer honeypot detector tool to check any token. The detector will simulate a buy and sell transaction to determine if a token is a honeypot. Also, do your own research and use our tool at your own risk.</p> </div>
  < div className = "honeypot" >
   <a href="https://honeypot.kookertoken.com"> Check Honeypot Tokens</a>
  </div>
 
 </div>
 
  )
}

export default Live