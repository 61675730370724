import React from 'react';

function Connect() {
  return (
  
  
  <div><h1>Swap</h1></div>
  
  );
}

export default Connect;
