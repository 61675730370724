import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './Donation.css'


function Donation(){
    const [copied, setCopied] = useState(false);
  const contract = '0x37a795984fCaddcA805d5057dA3792b35b5bB716';
    return (
        <div className='donation' >

<h3>Donation</h3>
<p>If you wish to support this community-driven project, please donate to our wallet below.</p>
<h4> DONATION ADDRESS: <br/>You can donate KooKer(KK), BNB, or ETH ...<br/> 
We appreciate your support!</h4>
      <div className={copied ? 'address-code address-applied' : 'address-code'} >
        <div className="kooker-code">{contract}</div>{
          copied ? <div className="address-copied">Copied!</div>:
            <CopyToClipboard text={contract} onCopy={() => setCopied(true)}>
              <div className="copy">Copy</div>
            </CopyToClipboard>
        }</div>
        </div >
  );

}

export default Donation;