import React, {useState, /* useEffect */ } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './Address.css';
//import KookerPrice from "./KookerPrice";


function Address() {
  
 /*  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch("https://api.pancakeswap.info/api/v2/tokens/0xa5283B31fB971dFcBc1dD9243B16152C782fCFBD")  
 
      .then((res) => res.json())
      .then((data) => {        
       setPrice(data.data.price); 
      //setPrice(data.result.tokenName); 
        setLoading(false);       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
*/
 const [copied, setCopied] = useState(false);
  const contract = '0xa5283B31fB971dFcBc1dD9243B16152C782fCFBD';

  return (
    <div className="address">
{/*
        
        <div className =' supply'> 
          <div className ='num'>
        
          <h1>Total Supply</h1>
          <p>69,000,000,000,000,000,000,000</p>
         
          <h1>LP Locked Address</h1>
          <p>LOCKED update Coming Soon</p>
         
          </div>
         
         
          </div>  




<div className="kk" style={{marginBottom: "0px", padding:"0px", justifyContent:"center",
     alignItems:"center"  ,alignContent:"center" }}>
            <div className="prices"  style={{padding:"5px", width:"100%",margin: "5px", justifyContent:"center" , textAlign:"center"
        }}> <h1 style={{ marginTop: "10px"}}>KooKer Price</h1>

          <div className="kk-logo" style={{   textAlign:"center",
     justifyContent:"center", alignItems:"center"  ,alignContent:"center",   display: "flex",
     marginTop:"10px"}}>
      
      

        <div className="kk-price" style={{color:"#ffffff", width:"100%",
        alignItems:"center" , fontSize:"30px" , fontWeight:"bold", marginTop:"15px"}} >
        
          {loading ? "LOADING" : "$" + price}
        </div>      
        </div>
        </div>
        </div>

        */}

<div className ="gradient">
<hr /> 
     </div>
<div className = "all">
      <h3 style={{textAlign:"center"}}>KooKer(KK) Contract Address</h3>
      <div className={copied ? 'address-code address-applied' : 'address-code'} >
        <div className="kooker-code">{contract}</div>{
          copied ? <div className="address-copied">Copied!</div>:
            <CopyToClipboard text={contract} onCopy={() => setCopied(true)}>
              <div className="copy">Copy</div>
            </CopyToClipboard>
        }</div>
        </div>
 
</div>
  
  );

}

export default Address;